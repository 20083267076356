// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;

// Small devices
@mixin sm {
  @media only screen and (min-width: #{$screen-sm-min}) {
    @content;
  }
}



// Medium devices
@mixin md {
  @media only screen and (min-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media only screen and (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media only screen and (min-width: #{$screen-xl-min}) {
    @content;
  }
}

#pagewrap {
  margin: 0px auto;
}

.app-header {
  padding: 20px;
}

nav.menu-items {
  // ul {
  //  list-style: none;
  //  background: #34495e;
  //  padding: 8px 0;
  //}

  li a {
    display: block;
    padding: 0 10px;
    color: #fff;
    text-decoration: none;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    line-height: 2em;
    height: 2em;
    border-bottom: 1px solid #383838;
  }
}

nav.menu-items li:last-child a {
  border-bottom: none;
}

nav.menu-items li a:hover,
nav.menu-items li a:focus {
  color: #1c1c1c;
  background: #ccc;
}

//
//.header, .footer {
//  height: 100px;
//  background: #666;
//  color: #fff;
//}

//.app-content {
//  display: flex;
//  flex: 1;
//  background: #999;
//  color: #000;
//}

.columns {
  display: flex;
  flex: 1;
  flex-direction: column;
}

//.main {
//  flex: 1;
//  order: 2;
//  background: #eee;
//}

//footer {
//  padding: 0 15px;
//  background-color: #000;
//  height: 50px;
//
//  align-items: center;
//  justify-content: center;
//
//  p {
//    margin-top: 5px;
//    color: white;
//    font-size: smaller;
//    text-align: center;
//  }
//}

//.rep {
//  width: 100%;
//  height: auto;
//}
//
//.welcome {
//  font-size: 85px;
//  margin: 20px;
//  display: block;
//  text-align: justify;
//  text-justify: inter-word;
//  color: slategray;
//}
//
//.welcome-sub {
//  font-size: 40px;
//  margin: 20px;
//  display: block;
//  text-align: justify;
//  text-justify: inter-word;
//  color: slategray;
//}

//p {
//  margin: 20px;
//  display: block;
//}

.main {
  flex: 1;
}

//.hamburger {
//  display: none;
//}
//.sidebar-second {
//  display: none;
//}

////Color Variables
//$color1: #E84545; //Red
//$color2: #53354A; //Plum
//$color3: #903749; //Maroon
//
//
//@media (min-width: 580px) {
//  .main {
//    flex: 1;
//    order: 2;
//    background: #eee;
//  }
//  .sidebar-first {
//    display: flex;
//    width: 280px;
//    background: #34495e;
//    order: 1;
//  }
//  .sidebar-second {
//    display: none;
//  }
//
//  .columns {
//    flex-direction:row;
//  }
//
//  nav.menu-items {
//
//    width: 100%;
//
//    ul {
//      height: auto;
//      margin: 0;
//      padding: 0;
//      flex: 1;
//      list-style-type: none;
//      justify-content: flex-start;
//    }
//
//    li {
//      float: none;
//      width: 100%;
//      padding-left: 0px;
//      margin: 0;
//      background: #34495e;
//      color: #FFFFFF;
//    }
//
//    a {
//      line-height: 50px;
//      color: #FFFFFF;
//      text-decoration: none;
//      cursor: hand;
//      transition: all .5s ease;
//
//      &:hover {
//        color: #cccccc;
//      }
//    }
//  }
//
//}

ul.pagination {
  display: inline-block;
  padding-left: 15px;
  padding-right: 15px;

  li {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;

  }
  a {
    cursor: default;
  }

  .active {
    background-color: red;
  }
}

//.channel-message-input {
//  width: 400px;
//  height: 20px;
//  background-color: #00b0f4;
//}
//
//

.toolkit {
  aside {
    flex: 0 0 280px;
  }

  font-size: 16px;
  h4 {
    margin: 35px 0 15px;
    font-weight: 400;
    color: #002c6c;
    font-size: 18px;
  }
  p {
    font-size: 16px;
    line-height: 24px;
    color: #454545;
    &.large {
      font-size: 18px;
      font-weight: 300;
      line-height: 26px;
    }
  }
  a {
    font-weight: 500;
    color: #008dbd;
    text-decoration: none;
    word-break: keep-all;
  }

  strong {
    font-weight: 500;
  }

  .logo {
    img {
      height: 43px;
      margin-right: 5px;
      @include md {
        height: 61px;
        margin-right: 0px;
      }
    }

    position: relative;
    float: left;

    @include lg {
      border-bottom: none;
    }

    p,
    h4 {
      display: inline-block;
      @include lg {
        font-size: 16px;
      }
    }
  }

  .site-content {
    padding-top: 25px;
    margin-top: 0;
    background-color: #f4f4f4;
    margin-bottom: 30px;
  }

  .page-header {
    margin: 40px 0 20px;
  }

  h1,
  h2,
  h3,
  h4,
  .footer h4 a,
  h5,
  h6 {
    font-weight: 400;
    color: #002c6c;
  }
  h1 {
    margin: 20px 0 20px;
    font-weight: 400;
    font-size: 36px;
  }

  .logo1 {
    display: flex;
    align-items: flex-start;
    float: left;
    margin-top: 15px;
    a {
      color: #454545;
    }
    p {
      font-size: 16px;
      line-height: 20px;
      position: relative;
      top: 3px;
    }

    @include md {
      margin-top: 0;
      padding-bottom: 31px;
      padding-top: 39px;
    }
  }

  li {
    line-height: 24px;
    font-weight: 300;
  }

  .secondary-nav {
    display: none;
    font-size: 12px;

    span[class^="icon"] {
      font-size: 18px;
      left: 40px;
      position: absolute;
      top: 10px;
    }

    ul.list-inline {
      li {
        a {
          padding-right: 5px;
          border-right: 1px solid rgb(177, 179, 179);
        }
        &:last-of-type a {
          padding-right: 0px;
          border-right: 0px;
        }
      }

      @include md {
        margin-bottom: 5px;
        margin-left: 0px;
        padding: 0px;
      }
    }

    .login-button {
      position: relative;
      vertical-align: top;
      @include md {
        margin-left: 15px;
        padding-left: 35px;
        padding-right: 15px;
      }
      @include lg {
        margin-left: 30px;
        padding-left: 70px;
        padding-right: 42px;
      }
    }
    @include md {
      display: block;
      float: right;
      margin-top: 35px;
      text-align: right;
    }
  }
  //a {
  //  font-weight: 500;
  //  color: #008dbd;
  //  text-decoration: none;
  //  word-break: break-word;
  //}

  header {

    @include md {
      height: 131px;
      min-height: 131px;
    }
    @include lg {
      height: 131px;
      min-height: 131px;
    }
    @include xl {
      height: 131px;
      min-height: 131px;
    }
  }

  .header-top {
    background-color: #f4f4f4;
    border-bottom: 1px solid #b1b3b3;
    display: none;
    @include md {
      display: block;
      height: 130px;
    }
    @include lg {
    }
    @include xl {
    }
  }

  .content-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .hero-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .hero-left {
    flex: 0 1 0;
    @include lg {
      flex-basis: 496px;
      flex-grow: 1;
    }
  }

  .hero-right {
    flex: 0 1 0;
    img {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
    }
    @include lg {
      flex-basis: 496px;
      flex-grow: 1;
    }
  }
  .toolkit-title {
    text-align: center;
  }

  .btn {
    display: inline-block;
    padding: 0;
    border-radius: 0;

    &-primary {
      color: #fff;
      border: 0;
      padding: 9px 16px 8px;
      background-color: #f26334;
    }
  }

  .panel-pane {
    margin: 15px;
  }
  .content-module-bar {
    height: 7px;
    pointer-events: none;
  }
  .content-module-padding {
    padding: 20px;
    margin-bottom: 20px;
  }

  .toolkit .content-module-container {
    margin-bottom: 30px;
  }

  .content-module {
    position: relative;
    background-color: #fff;
    border: 1px solid #b1b3b3;
    border-top: 0;

    a {
      font-weight: 300;
    }
    h4 {
      line-height: 26px;
      padding-bottom: 10px;
      margin-top: 0;
      margin-bottom: 15px;
      border-bottom: 1px solid #b2b2b8;
    }
    ul {
      margin-bottom: 30px;
      //      margin-left: -40px;
      list-style: none;
      margin: 0px;
      padding: 0px;
    }
    li {
      padding-top: 5px;
      padding-bottom: 5px;
      border-bottom: 1px solid rgb(177, 179, 179);
    }

    .field-name-field-bean-image img {
      max-width: 100%;
      height: auto;
    }
  }

  footer {
    margin: 20px 0;
    a {
      font-weight: 300;
    }
    p {
      display: block;
      margin: 0;
      font-size: 12px;
    }
    h4 a {
      font-weight: 400;
      color: #002c6c;
    }
    .footer-grid-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .social {
      text-align: right;
      a {
        color: #002c6c;
      }
      .icon {
        font-size: 30px;
      }
    }

    ul {
      padding: 0;
      margin-bottom: 20px;
      list-style-type: none;

      li {
        margin-bottom: 7px;
        line-height: 1em;
        & > a {
          font-size: 0.875em;
        }
      }
    }
  }

  .address {
    margin-top: 0.5em;
    p {
      line-height: 1.5;
    }
  }

  .panel-pane {
    flex: 1 0 auto;
    @include lg {
      flex: 1 0 250px;
    }
    @include xl {
      flex: 1 0 345px;
    }
  }
  .news-event {
    display: flex;
    justify-content: flex-start;
  }
  .news-events-date {
    padding-top: 6px;
    color: rgb(177, 179, 179);
    flex: 0 0 50px;
    .day {
      display: block;
      font-size: 1.875em;
      font-weight: 500;
      letter-spacing: -1px;
    }
    .month {
      font-weight: 400;
      display: block;
    }
  }

  .news-events-right {
    flex: 1 0 auto;
    @include lg {
      flex: 1 0 120px;
    }

    @include xl {
      flex: 1 0 250px;
    }
    white-space: pre-wrap;
    .item-title {
      margin-bottom: 0px;
      line-height: 1;
    }
  }

    ul.left-menu {
      li {
        padding: 8px 5px 8px 40px;
        border-bottom: 1px solid #383838;
        font-style: normal;
        font-size: 18px;

        &.is-active {
          background-color: #f26334;
        }
        a {
          color: white;

        }
      }
    }

}

.left-pane {

    flex: 0 0 300px;

  @media screen and (max-width: 600px) {
    flex: 0 1 3px;
    display:none;
    visibility:hidden;

  }
}

.menu-pane {

  display:none;
  visibility:hidden;

  @media screen and (max-width: 600px) {
    display:block;
    visibility:visible;
    background-color: #002c6c !important;
  }
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
  overflow: hidden;

  @include md {
    width: 750px;
  }
  @include lg {
    width: 970px;
  }

  @include xl {
    width: 1170px;
  }
}


.inner-container {
  background: white;
  padding-left: 20px;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  box-shadow: 3px 3px 5px 0px rgba(100,100,100,0.55);
}


.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}