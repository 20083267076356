@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;

// Small devices
@mixin sm {
  @media only screen and (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media only screen and (min-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media only screen and (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media only screen and (min-width: #{$screen-xl-min}) {
    @content;
  }
}

.bg-light-gray {
  background-color: #f4f4f4 !important;
}


.bg-blue {
  background-color: #002c6c !important;
}

.bg-orange {
  background-color: #f26334 !important;
}

.bg-sky {
  background-color: #00b6de !important;
}


$mb-primary: #1ca3ec;

$gs-blue: #00B7DE;

// Set your brand colors
//$purple: #8A4D76;
//$pink: #FA7C91;
//$brown: #757763;
$beige-light: #D0D1CD;
$beige-lighter: #EFF0EB;
//
//// Update Bulma's global variables
//$family-sans-serif: "Nunito", sans-serif;
//$grey-dark: $brown;
//$grey-light: $beige-light;
//$primary: $purple;
//$link: $pink;
//$widescreen-enabled: false;
//$fullhd-enabled: false;
//
$body-background-color: $beige-lighter;
//$control-border-width: 2px;
//$input-border-color: transparent;
//$input-shadow: none;
//
//
$hr-margin: 0 0;

@import "../../node_modules/bulma/bulma.sass";

.rounded-avatar-container {
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
}

.rounded-avatar {
  width: inherit;
  height: inherit;
  border-radius: inherit;

  object-fit: cover;
  object-position: center;
}

.is-vertical-center {
  display: flex;
  align-items: center;
}

.circle200 {
  display: block;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  text-align: center;
  align-content: center;
  align-items: center;
}

#container-floating {
  position: fixed;
  width: 70px;
  height: 70px;
  bottom: 30px;
  right: 30px;
  z-index: 50;
}

#floating-button {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  background: #db4437;
  position: fixed;
  bottom: 30px;
  right: 30px;
  cursor: pointer;
  box-shadow: 0px 2px 5px #666;
}

.plus {
  color: white;
  position: absolute;
  top: 0;
  display: block;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  padding: 0;
  margin: 0;
  line-height: 55px;
  font-size: 38px;
  font-family: 'Roboto';
  font-weight: 300;
  animation: plus-out 0.3s;
  transition: all 0.3s;
}

//.table-main {
//  width: 600px;
//}

.company-title {
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
}

.company-value {
  width: 400px;
  overflow: hidden;
  white-space: nowrap;
}

.circle {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.title-container {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 30px;
}

.title-element {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  margin-left: 10px;
}

.company-name {
  color: $gs-blue
}

.note-row {
  width: 600px;
}

.note-item-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.note-element-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}

.note-item-left {

}

.note-item-right {

}

.limiter {
  width: 100%;
  margin: 0 auto;
}

.container-login {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;;
}

.wrap-login {
  width: 390px;
  background: #fff;
  border-radius: 10px;
  position: relative;
}

.dashboard-header-container {
  display: flex;
  flex-flow: row wrap;

  /* Then we define how is distributed the remaining space */
  justify-content: space-around;
  align-items: center;
  height: 50px;
}

.dashboard-header-left-item {
  padding-left: 20px;
  span {
    color: deepskyblue;
    font-size: 18px;
    font-weight: 500;

  }

}

.dashboard-header-right-item {
  margin-left: auto;
  padding-right: 20px;
  span {
    color: black;
    font-size: 28px;
    font-weight: 500;
  }

}

.dashboard-main {
  height: 150px;
}

.dashboard-footer-container {
  display: flex;
  flex-flow: row wrap;
  background-color: #efefef;
  /* Then we define how is distributed the remaining space */
  justify-content: space-around;
  align-items: center;
  height: 80px;
}

.dashboard-footer-item {

  text-align: center;
  padding-right: 20px;
  padding-left: 20px;
  span {
    color: black;
    font-size: 20px;
    font-weight: 200;
  }
}

.gs1blue {
  color: #002c6c;
}

.gs1orange {
  color: #f26334;
}

.bg-blue {
  background-color: #002c6c !important;
}

.bg-orange {
  background-color: #f26334 !important;
}


.content-container-main {
  display: flex;
  justify-content: space-between;
}

@import "tailwindcss/base";

@import "tailwindcss/components";

@import "tailwindcss/utilities";